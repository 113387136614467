/* Main app */
.header {
  padding-top: 12px;
  background: #fff;
}
.content {
  background-color: #c9e9cc;
}

.triger-btn {
  font-size: 16px;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

/* Sider */
.sider {
  height: 100vh;
  position: sticky !important;
  left: 0;
  bottom: 0;
  top: 0;
}

.logo {
  color: #4f6f52;
  margin: 5px 0;
  font-size: 1.5rem;
}
.menu-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
}

.ant-menu-item-selected {
  background-color: #4f6f52 !important;
  color: #fff !important;
}

.page-content {
  margin: 24px 16px;
  padding: 20px;
}
.btn {
  background-color: #4f6f52 !important;
}
