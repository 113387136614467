.appBg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
.loginForm {
  background-color: rgba(255, 255, 255, 0.33);
  box-shadow: 0px 8px 32px 0 rgba(0, 0, 255, 0.3);
  padding: 0px 16px 16px 16px;
  border-radius: 16px;
  width: 350px;
  height: 300px;
}
